import { makeStyles } from "@mui/styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStyles = makeStyles((theme: any) => ({
  arrow: (props: { topArrow: string | undefined }) => {
    const { topArrow } = props;
    const arrows: any = {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
      color: theme.palette?.general.main,

      backgroundColor: "white",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: topArrow || "50%",
      transform: `translateY(${topArrow || "-50%"})`,
      zIndex: 2,
      cursor: "pointer",
      "&:hover": {
        color: "inherit",
        backgroundColor: "white",
      },
      "&:before": {
        display: "none",
      },
      "& svg": {
        width: "14px",
        height: "14px",
      },
    };
    return arrows;
  },
  next: {
    right: "-20px",
  },
  prev: {
    left: "-20px",
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
}));
