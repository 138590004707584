import React from "react";
import { Box, BoxProps, Breakpoint, Container, Typography } from "@mui/material";
import Text from "@ds/components/Text";

export interface SectionProps extends BoxProps {
  title?: string;
  description?: string;
  subtitle?: string;
  maxWidth?: Breakpoint | "full-width";
  variant?: "white" | "gray";
  paddingT?: number;
  paddingD?: number;
}

export const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  description,
  maxWidth = "lg",
  variant = "white",
  paddingT,
  paddingD,
  children,
  ...props
}) => {
  const bgColor = () => {
    switch (variant) {
      case "gray":
        return "#F5F7FA";

      case "white":
      default:
        return "#FFF";
    }
  };

  return (
    <Box component="section" pt={paddingT || 7} pb={paddingD || 9} bgcolor={bgColor()} {...props}>
      <Container maxWidth="md">
        {!!(title || subtitle || description) && (
          <Box textAlign="center" marginBottom="60px">
            {!!subtitle && (
              <Typography variant="subtitle1" marginBottom="16px">
                {subtitle}
              </Typography>
            )}
            {!!title && (
              <Typography variant="h2" component="h2" marginBottom="16px">
                {title}
              </Typography>
            )}
            {!!description && (
              <Text variant="body1" color="inactive-secondary">
                {description}
              </Text>
            )}
          </Box>
        )}
      </Container>
      {maxWidth === "full-width" ? (
        <Box>{children}</Box>
      ) : (
        <Container maxWidth={maxWidth}>{children}</Container>
      )}
    </Box>
  );
};
