/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from "@mui/styles";
import color from "@definitions/mui/color";

type TStyleOfLandingPage = {
  isVisible: boolean;
};

const useStyles = makeStyles(() => ({
  btnscroll: (props: TStyleOfLandingPage) => {
    const { isVisible } = props;

    const baseBtnScroll: any = {
      position: "fixed",
      bottom: "4rem",
      left: "50%",
      transform: "translateX(-50%)",
      minWidth: 164,
      minHeight: 44,
      boxShadow: "0 2px 20px 0 rgba(46, 67, 77, 0.1)",
      backgroundColor: color.white,
      transition: "opacity 300ms ease",
      opacity: isVisible ? "1" : "0",
      pointerEvents: isVisible ? undefined : "none",
      zIndex: 1,
    };

    return baseBtnScroll;
  },
  "@keyframes bounceArrow": {
    "0%, 20%, 50%, 80%, 100%": {
      transform: "translateY(3px)",
    },
    "40%": {
      transform: "translateY(8px)",
    },
    "60%": {
      transform: "translateY(8px)",
    },
  },
  bounce: {
    animation: "$bounceArrow 2000ms infinite",
  },
}));

export default useStyles;
