import React from "react";
import { useRouter } from "next/router";
import { Settings } from "react-slick";
import { Carousel } from "@atoms/Carousel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DynamicImage from "@ds/components/DynamicImage";

interface Localizations {
  name: string;
  jobTitle: string;
  description: string;
}

export interface TestimoniesItem {
  image: {
    mediaPath?: string;
    mediaName?: string;
    mediaId?: string;
  };
  localizations: Localizations;
}

export interface Testimony {
  isAutoTranslate?: boolean;
  testimonies: Array<TestimoniesItem>;
}

export interface TestimonialsProps {
  data: Testimony;
  minHeight?: string | number;
  carouselSettings?: Settings;
  isNotDisableArrows?: boolean;
}

export const Testimonials: React.FC<TestimonialsProps> = ({
  data,
  minHeight,
  isNotDisableArrows = true,
}) => {
  const router = useRouter();

  const testimonyDescriptions = (item: Localizations) => (
    <>
      <Typography variant="body1" fontWeight="bold">
        {item.name}
      </Typography>
      <Box fontSize="14px" marginBottom="24px" color="inactive.secondary">
        {item.jobTitle}
      </Box>
      <Typography variant="body1" fontFamily="Nunito">
        {item.description}
      </Typography>
    </>
  );
  return (
    <Carousel settings={{ dots: true }} arrows={isNotDisableArrows}>
      {data.testimonies?.map((item, index) => {
        const { image }: any = item;
        return (
          <Box
            textAlign="center"
            bgcolor="white"
            borderRadius="8px"
            // padding="50px 24px"
            px={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            minHeight={minHeight}
            border="1px solid #DEE3ED"
            key={index}
          >
            <Box mx="auto" mb="10px" mt="20px" width={96} height={96}>
              <DynamicImage
                src={image.mediaPath}
                alt={image.mediaName}
                blurDataURL={item.image.mediaPath}
                placeholder="blur"
                width={96}
                height={96}
                quality={75}
              />
            </Box>
            {Array.isArray(item.localizations)
              ? item.localizations
                  .filter((val: any) => val.language === router.locale)
                  .map((item: Localizations) => testimonyDescriptions(item))
              : testimonyDescriptions(item.localizations)}
          </Box>
        );
      })}
    </Carousel>
  );
};
