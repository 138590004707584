import React from "react";
import Link from "next/link";
import sanitizeHtml from "sanitize-html";
import Box from "@mui/material/Box";
import { BoxProps, useMediaQuery, useTheme } from "@mui/material";
import color from "@definitions/mui/color";
import DynamicImage from "@ds/components/DynamicImage";

export interface ProductCardProps extends Omit<BoxProps, "title"> {
  title: React.ReactNode | string;
  tagTitle?: string;
  subTitle?: React.ReactNode | string;
  sideImage?: string;
  image?: string;
  label?: React.ReactNode | string;
  imageHeight?: number;
  withImagePlaceholder?: boolean;
  description?: string | React.ReactNode;
  htmlDescription?: string;
  linkTo?: string;
  titleMaxLine?: number;
  descriptionMaxLine?: number;
  padding?: string;
  textColor?: string;
  imgWidth?: number | string;
  imgHeight?: number | string;
  imgQuality?: number;
  imageInsideBox?: boolean;
  imageUrl?: string;
  priority?: boolean;
  labelInline?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  image,
  imageHeight = 152,
  title,
  tagTitle,
  subTitle,
  label,
  withImagePlaceholder,
  children,
  linkTo,
  description,
  htmlDescription = "",
  titleMaxLine = 3,
  descriptionMaxLine = 2,
  sideImage,
  padding = "16px", // padding props to set your padding custom
  textColor = "#808C92",
  imgWidth, // set image width as parameter to handle size for imageKit
  imgHeight, // set image height as parameter to handle size for imageKit
  imgQuality, // set image quality as parameter to handle size for imageKit
  imageInsideBox,
  imageUrl,
  priority = false, // when true, the image will be considered high priority and preload.
  labelInline,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const contentHtmlSanitize: string = sanitizeHtml(htmlDescription, {
    allowedTags: ["span"],
    allowedAttributes: {},
    transformTags: {
      br: "span",
    },
  });
  const imageElement = () => {
    if (image) {
      return (
        <Box>
          <DynamicImage
            src={image}
            alt={image}
            placeholder="blur"
            blurDataURL={image}
            width={imgWidth}
            height={imgHeight}
            quality={imgQuality}
            priority={priority}
          />
        </Box>
      );
    }

    if (withImagePlaceholder) {
      return (
        <Box
          width="100%"
          height={imageHeight + "px"}
          marginBottom="16px"
          display="flex"
          sx={{
            alignItems: "center",
            background: "#eee",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            color: "#999",
            fontWeight: "600",
            justifyContent: "center",
          }}
        >
          <div>No image</div>
        </Box>
      );
    }

    if (imageInsideBox) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      );
    }

    return null;
  };

  const content = (
    <>
      <Box mb={1} overflow="hidden" height={imageHeight + "px"}>
        {imageElement()}
      </Box>
      <Box padding={padding} paddingTop="0">
        {!!label && (
          <Box
            bgcolor={labelInline ? "transparent" : "#293780"}
            color={labelInline ? color.blue.secondary : "#fff"}
            padding={labelInline ? "0 0 8px 0" : "6px 12px"}
            borderRadius="40px"
            fontSize="14px"
            display="inline-block"
            position={labelInline ? "relative" : "absolute"}
            top={labelInline ? "unset" : imageHeight - 50 + "px"}
          >
            {label}
          </Box>
        )}
        {sideImage ? (
          <Box display="flex" justifyContent="flex-start" gap={1} flexDirection="row" width="100%">
            <Box component="img" src={sideImage} alt="dashboard-image" width="35px" height="35px" />
            <Box>{title}</Box>
          </Box>
        ) : (
          <>
            {tagTitle ? (
              <Box fontFamily="Nunito" fontSize="14px" pb="8px" color={color.blue.secondary}>
                {tagTitle}
              </Box>
            ) : null}
            <Box
              fontFamily={isMobile ? "Nunito" : "Open Sans"}
              fontSize={isMobile ? "16px" : "20px"}
              fontWeight="600"
              lineHeight="30px"
              overflow="hidden"
              textOverflow="ellipsis"
              display="-webkit-box"
              color={color.general.main}
              sx={{
                WebkitLineClamp: titleMaxLine,
                WebkitBoxOrient: "vertical",
              }}
              className="productcard-title"
            >
              {title}
            </Box>
          </>
        )}

        {!!description && (
          <Box
            fontSize="14px"
            fontWeight="400"
            mt={1}
            overflow="hidden"
            textOverflow="ellipsis"
            display="-webkit-box"
            fontFamily="Nunito"
            sx={{
              WebkitLineClamp: descriptionMaxLine,
              WebkitBoxOrient: "vertical",
            }}
          >
            {typeof description === "string"
              ? description.replace(/<\/?[^>]+(>|$)/g, "")
              : description}
          </Box>
        )}
        {!!htmlDescription && (
          <Box
            fontSize="14px"
            fontWeight="400"
            mt={1}
            overflow="hidden"
            textOverflow="ellipsis"
            display="-webkit-box"
            fontFamily="Nunito"
            color={textColor}
            sx={{
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            dangerouslySetInnerHTML={{ __html: contentHtmlSanitize }}
          />
        )}
        {!!subTitle && (
          <Box
            color="#808C92"
            fontSize="12px"
            fontWeight="400"
            marginTop="10px"
            fontFamily="Nunito"
          >
            {subTitle}
          </Box>
        )}
        {children}
      </Box>
    </>
  );

  return (
    <Box
      bgcolor="#FDFDFD"
      borderRadius="12px"
      overflow="hidden"
      border="1px solid #DEE3ED"
      color="#2E434D"
      position="relative"
      sx={{
        cursor: linkTo ? "pointer" : undefined,
        "&:hover": {
          backgroundColor: color.inactive.primary,
        },
      }}
      {...props}
    >
      {linkTo ? (
        <Link href={linkTo} passHref>
          <a>{content}</a>
        </Link>
      ) : (
        content
      )}
    </Box>
  );
};
