import * as React from "react";
import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SvgChevronIcon = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 7 10">
      <path d="M1.33334 0L0.15834 1.175L3.97501 5L0.15834 8.825L1.33334 10L6.33334 5L1.33334 0Z" fill="currentColor"/>
    </SvgIcon>
  );
};

export default SvgChevronIcon;
