import React, { Children, ReactNode } from "react";
import Slider, { Settings, CustomArrowProps } from "react-slick";
import { Box } from "@mui/material";
import Chevron from "@icons/Chevron";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStyles } from "./styles";
export interface CarouselProps {
  settings?: Settings;
  arrowPosition?: string;
  centerWhenLessThenLimit?: boolean;
  arrows?: boolean;
  centerMode?: boolean;
  topArrow?: string;
  children: ReactNode;
  maxWidth?: string;
}

interface ArrowProps extends CustomArrowProps {
  isNext?: boolean;
  arrowPosition?: string;
  topArrow?: string;
}

const Arrow: React.FC<ArrowProps> = ({ isNext, arrowPosition, ...props }) => {
  const { topArrow } = props;
  const classes = useStyles({ topArrow });

  return (
    <Box
      {...props}
      className={[
        classes.arrow,
        isNext ? classes.next : classes.prev,
        isNext ? "arrow-next" : "arrow-prev",
      ].join(" ")}
      sx={{
        [isNext ? "right" : "left"]: `${arrowPosition} !important`,
      }}
    >
      <Chevron />
    </Box>
  );
};

export const Carousel: React.FC<CarouselProps> = ({
  settings: additionalSettings,
  arrowPosition,
  children,
  arrows = true,
  centerMode = false,
  centerWhenLessThenLimit = true,
  topArrow,
  maxWidth = "320px",
}) => {
  const slidesToShow = additionalSettings?.slidesToShow;
  const childrens = Children.toArray(children);

  const handleLessChildren = () => {
    if (slidesToShow && childrens.length < slidesToShow) {
      return Array(slidesToShow)
        .fill("")
        .map((_, index) => {
          if (index >= childrens.length) {
            return <div key={index} />;
          }
          return childrens[index];
        });
    }
    return children;
  };

  const settings: Settings = {
    infinite: true,
    centerMode: centerMode,
    speed: 500,
    arrows: arrows,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow isNext arrowPosition={arrowPosition} topArrow={topArrow} />,
    prevArrow: <Arrow arrowPosition={arrowPosition} topArrow={topArrow} />,
    ...additionalSettings,
  };

  if (centerWhenLessThenLimit && slidesToShow && childrens.length < slidesToShow) {
    return (
      <Box display="flex" justifyContent="center" sx={{ "& > div": { maxWidth: maxWidth } }}>
        {childrens}
      </Box>
    );
  }

  return <Slider {...settings}>{handleLessChildren()}</Slider>;
};
