import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Text from "@ds/components/Text";
import useStyles from "./styles";

const FABScrollDown: React.FC<any> = ({ onClick, ...props }) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const isPageAbout = router.pathname.includes("/tentang-kami");
  const [isVisible, setIsVisible] = useState(true);
  const classes = useStyles({ isVisible });

  const _onScroll = () => {
    const limitation = 500;
    const windowScroll = window.scrollY;

    if (windowScroll > limitation && windowScroll !== 0) {
      setIsVisible(false);
    } else if (isPageAbout && windowScroll > 80) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _onScroll);
    return () => {
      window.removeEventListener("scroll", _onScroll);
    };
  }, []);

  return (
    <Fab className={classes.btnscroll} variant="extended" onClick={onClick} {...props}>
      <Box className={classes.bounce}>
        <KeyboardArrowDownRoundedIcon
          css={{ width: "1.25rem", height: "1.25rem" }}
          color="primary"
        />
      </Box>
      {isPageAbout ? (
        <Text color="primary-main" variant="subtitle1" sx={{ marginLeft: "4px" }}>
          {t("about.learn")}
        </Text>
      ) : (
        <Text color="primary-main" variant="subtitle1" sx={{ marginLeft: "4px" }}>
          {t("scroll")}
        </Text>
      )}
    </Fab>
  );
};

export default FABScrollDown;
